var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',{staticClass:"my-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 text-md-h3"},[_vm._v("Maestro de Productos")])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-md-end",attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('v-btn',{attrs:{"outlined":"","elevation":"7","color":"secondary"},on:{"click":function($event){_vm.crear = !_vm.crear}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.crear),expression:"crear"}],attrs:{"cols":"3"}},[_c('v-form',{ref:"productForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"counter":7,"rules":_vm.productRules.codigo,"label":"Código"},model:{value:(_vm.product.code),callback:function ($$v) {_vm.$set(_vm.product, "code", $$v)},expression:"product.code"}}),_c('v-text-field',{attrs:{"rules":_vm.productRules.descripcion,"label":"Descripción"},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}}),_c('v-select',{attrs:{"items":_vm.items,"rules":[function (v) { return !!v || 'Seleccione la clase del producto'; }],"label":"Clase"},model:{value:(_vm.product.class_id),callback:function ($$v) {_vm.$set(_vm.product, "class_id", $$v)},expression:"product.class_id"}}),(_vm.product.class_id == 1)?[_c('v-select',{attrs:{"items":_vm.subclass_items,"rules":[
                function (v) { return (_vm.product.class !== 'MATERIA PRIMA' && !!v) ||
                  'Seleccione una subclase para la materia prima'; }
              ],"label":"Subclase"},model:{value:(_vm.rawmaterial.subclass),callback:function ($$v) {_vm.$set(_vm.rawmaterial, "subclass", $$v)},expression:"rawmaterial.subclass"}}),_c('v-select',{attrs:{"items":_vm.unit_items,"rules":[
                function (v) { return (_vm.product.class !== 'MATERIA PRIMA' && !!v) ||
                  'Seleccione una unidad para la materia prima'; }
              ],"label":"Unidad"},model:{value:(_vm.rawmaterial.unit),callback:function ($$v) {_vm.$set(_vm.rawmaterial, "unit", $$v)},expression:"rawmaterial.unit"}})]:_vm._e(),(_vm.product.class_id == 2)?[_c('v-select',{attrs:{"items":_vm.fproduct_subclasses,"rules":[
                function (v) { return (_vm.product.class !== 'PRODUCTO TERMINADO' && !!v) ||
                  'Seleccione una subclase para el producto terminado'; }
              ],"label":"Subclase"},model:{value:(_vm.fproduct.subclass),callback:function ($$v) {_vm.$set(_vm.fproduct, "subclass", $$v)},expression:"fproduct.subclass"}}),_c('v-select',{attrs:{"items":_vm.fproduct_units,"rules":[
                function (v) { return (_vm.product.class !== 'PRODUCTO TERMINADO' && !!v) ||
                  'Seleccione una unidad para el producto terminado'; }
              ],"label":"Unidad"},model:{value:(_vm.fproduct.unit),callback:function ($$v) {_vm.$set(_vm.fproduct, "unit", $$v)},expression:"fproduct.unit"}})]:_vm._e(),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],2)],1),(!!this.products)?_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"item-key":"codigo","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions.",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v(" mdi--edit ")])]}}],null,false,1043925378)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }