<template>
  <div class="home">
    <v-container class="my-6">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-h5 text-md-h3">Maestro de Productos</div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="6"
          align-self="center"
          class="d-flex justify-md-end"
        >
          <v-btn
            outlined
            elevation="7"
            color="secondary"
            @click="crear = !crear"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="crear" cols="3">
          <v-form ref="productForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="product.code"
              :counter="7"
              :rules="productRules.codigo"
              label="Código"
            ></v-text-field>

            <v-text-field
              v-model="product.description"
              :rules="productRules.descripcion"
              label="Descripción"
            ></v-text-field>

            <v-select
              v-model="product.class_id"
              :items="items"
              :rules="[v => !!v || 'Seleccione la clase del producto']"
              label="Clase"
            ></v-select>

            <template v-if="product.class_id == 1">
              <v-select
                v-model="rawmaterial.subclass"
                :items="subclass_items"
                :rules="[
                  v =>
                    (product.class !== 'MATERIA PRIMA' && !!v) ||
                    'Seleccione una subclase para la materia prima'
                ]"
                label="Subclase"
              >
              </v-select>
              <v-select
                v-model="rawmaterial.unit"
                :items="unit_items"
                :rules="[
                  v =>
                    (product.class !== 'MATERIA PRIMA' && !!v) ||
                    'Seleccione una unidad para la materia prima'
                ]"
                label="Unidad"
              ></v-select>
            </template>
            <template v-if="product.class_id == 2">
              <v-select
                v-model="fproduct.subclass"
                :items="fproduct_subclasses"
                :rules="[
                  v =>
                    (product.class !== 'PRODUCTO TERMINADO' && !!v) ||
                    'Seleccione una subclase para el producto terminado'
                ]"
                label="Subclase"
              >
              </v-select>
              <v-select
                v-model="fproduct.unit"
                :items="fproduct_units"
                :rules="[
                  v =>
                    (product.class !== 'PRODUCTO TERMINADO' && !!v) ||
                    'Seleccione una unidad para el producto terminado'
                ]"
                label="Unidad"
              ></v-select>
            </template>
            <v-btn color="secondary" @click="save"> Guardar </v-btn>
          </v-form>
        </v-col>
        <v-col v-if="!!this.products">
          <v-data-table
            :headers="headers"
            :items="products"
            item-key="codigo"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:item.actions.="{ item }">
              <v-icon medium @click="editProduct(item)">
                mdi--edit
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    search: '',
    clase_producto: false,
    products: [],
    crear: false,
    items: [
      { text: 'MATERIA PRIMA', value: 1 },
      { text: 'PRODUCTO TERMINADO', value: 2 },
      { text: 'ACTIVO', value: 3 },
      { text: 'SERVICIO', value: 4 },
      { text: 'MATERIA INDIRECTA', value: 5 }
    ],
    subclass_items: ['EMPAQUE', 'INSUMO'],
    fproduct_subclasses: [
      'CARAMELO',
      'CARAMELO SACHET',
      'GALLETA',
      'PALITO',
      'GRANOLA'
    ],
    unit_items: ['UND', 'K'],
    fproduct_units: ['UND', 'SCH'],
    product: {},
    rawmaterial: {},
    fproduct: {},
    productRules: {
      codigo: [
        v => !!v || 'Ingrese el código de producto',
        v => (v && v.length === 7) || 'El código debe tener 7 caracteres'
      ],
      descripcion: [v => !!v || 'Ingresa la descripción de producto']
    },
    valid: true
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'code'
        },
        {
          text: 'Descripción',
          value: 'description',
          filtereable: true
        },
        { text: 'Clase', value: 'class' },
        { text: 'Accciones', value: 'actions' }
      ]
    }
  },
  mounted: function() {
    this.$makeGetRequest('/api/products').then(response => {
      this.products = response.data.data
      console.log(response)
    })
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      )
    },
    async save() {
      if (this.$refs.productForm.validate()) {
        let extra
        if (this.product.class_id === 1) {
          extra = this.rawmaterial
        } else {
          extra = this.fproduct
        }
        try {
          let response = await this.$makePostRequest('api/products/store', {
            product: this.product,
            rawmaterial: extra
          })
          console.log(response)
          this.$makeGetRequest('/api/products').then(response => {
            console.log('Update data')
            this.products = response.data.data
            this.rawmaterial = {}
            this.$showMessage('Producto insertado', 'success')
          })
        } catch (err) {
          console.log(err.response.data)
          let err_data = err.response.data

          this.$showMessage(
            `Hubo un error al insertal el producto: ${err_data.message}`,
            'error'
          )
        }
      }
    }
  }
}
</script>
